require('./bootstrap');

$(function () {
    $('.single-item').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        mobileFirst: true,
        autoplay: true,
        autoplaySpeed: 5000,

    });

    $(window).resize(function () {
        if ($(window).width() > 1061) {
            $('#navcheck').prop("checked", false);
        }
    });

    // $('#mainnav').on('show.bs.collapse', function () {
    //     $(this).parents('body').addClass('nav-o');
    // });
    // $('#mainnav').on('hide.bs.collapse', function () {
    //     $(this).parents('body').removeClass('nav-o');
    // });

    $('.dropdown-toggle').attr('data-toggle', 'dropdown');

    $(document).keyup(function (e) {
        if (e.keyCode == 27) { // escape key maps to keycode `27`
            $('.navbar').removeClass('open')
        }
    });
});
